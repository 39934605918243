import {
	ArrowUpward, ArrowDownward, ContentCopy, Fingerprint, MarkEmailRead, PermIdentity, Pix, SystemSecurityUpdateGood, Verified, WalletOutlined,
} from '@mui/icons-material'
import moment from "moment"
import React, { useEffect, useState } from 'react'

import { history } from '../../../../helpers/history'
import Loading from "../../../../components/Loading"
import Title from "../../../../components/Title"
import { formatStringByMask } from "../../../../helpers/formatStringByMask"
import Button from '../../../../components/Button'
import users from '../../../../services/users'
import Alert from '@material-ui/lab/Alert'
import formatDate from '../../../../helpers/formatDate'
import Table from '../../../../components/Table'
import { toast } from 'react-toastify'
import TagItem from '../../../../components/TagItem'
import { OpenInNew } from '@material-ui/icons'
import orderService from '../../../../services/orders'
import { handleSingleOrder, orderPayment, orderStatus } from '../../../Financial/Orders'
import withdrawSevices from '../../../../services/withdraw'
import { Popover, PopoverContent, PopoverTrigger, Button as UIButton } from '@nextui-org/react'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import userService from '../../../../services/users'
import { formatCurrency } from '../../../../helpers/formatCurrency'
import concoursesService from '../../../../services/concourse'
import MUSIcon from '../../../../assets/images/mus.svg'
import { useParams } from 'react-router-dom'
import affiliateService from '../../../../services/affiliate'
import { getStatusItem } from '../../../../constants/default'

function ResumeTable({title, onSeeMore = () => null, ...props}) {
	return (
		<div className="flex flex-col">
			<div className="flex items-center mb-4 pb-4 border-b border-solid border-neutral-6 gap-x-4">
				<h3 className="text-lg m-0 p-0 font-medium">{title}</h3>
			</div>
			<Table key={title} withBackground {...props} />
		</div>
	)
}

const transactionType = [
	{
		"raw": "withdraw",
		"label": "Saque",
		icon: <ArrowUpward style={{ fill: "#D86470" }} />,
	},
	{
		"raw": "deposit",
		"label": "Depósito",
		icon: <ArrowDownward style={{ fill: "#209869" }} />,
	},
]

const _status = [
	{
		'raw': 'pending',
		'label': 'Pendente',
		color: "#D8C364",
	},
	{
		'raw': 'sented',
		'label': 'Enviado',
		color: "#209869",
	},
	{
		'raw': 'requested',
		'label': 'Solicitado',
		color: "#209869",
	},
	{
		'raw': 'paid_out',
		'label': 'Pago',
		color: "#209869",
	},
	{
		'raw': 'canceled',
		'label': 'Cancelado',
		color: "#209869",
	},
	{
		'raw': 'completed',
		'label': 'Completo',
		color: "#209869",
	},
	{
		'raw': 'received',
		'label': 'Recebido',
		color: "#209869",
	},
	{
		'raw': 'processing',
		'label': 'Em processamento',
		color: "#209869",
	},
	{
		'raw': 'paid',
		'label': 'Pago',
		color: "#209869",
	},
	{
		'raw': 'rejected',
		'label': 'Rejeitado',
		color: "#209869",
	},
	{
		'raw': 'inreview',
		'label': 'Em Revisão',
		color: "#209869",
	},
	{
		'raw': 'awaiting',
		'label': 'Aguardando',
		color: "#209869",
	},
	{
		'raw': 'processed',
		'label': 'Processado',
		color: "#209869",
	},
	{
		'raw': 'reversed',
		'label': 'Reembolsado',
		color: "#209869",
	},
	{
		'raw': 'invalid_data_input',
		'label': 'Dados inválidos',
		color: "#209869",
	},
	{
		'raw': 'error',
		'label': 'Erro',
		color: "#209869",
	}
]

const validationList = [
	{
		value: 'cpf_verified_at',
		label: "CPF",
		icon: <PermIdentity className="text-primary text-[20px]" />
	},
	{
		value: 'email_verified_at',
		label: "Email",
		icon: <MarkEmailRead className="text-primary text-[20px]" />
	},
	{
		value: 'phone_verified_at',
		label: "Telefone",
		icon: <SystemSecurityUpdateGood className="text-primary text-[20px]" />
	},
	{
		value: 'face_match_verified_at',
		label: "Identidade",
		icon: <Fingerprint className="text-primary text-[20px]" />
	},
]

const _type = [
	{
		'type': 'C/C',
		'label': 'Conta Corrente'
	},
	{
		'type': 'C/I',
		'label': 'Conta Poupança'
	},
	{
		'type': 'C/P',
		'label': 'Conta Pagamento'
	}
]

const musStatus = [
	{
		"raw": "scheduled",
		"label": "Agendado",
		color: "#1186ea",
	},
	{
		"raw": "pending",
		"label": "Pendente",
		color: "#D8C364",
	},
	{
		"raw": "cancelled",
		"label": "Cancelado",
		color: "#CB784E",
	},
	{
		"raw": "deposited",
		"label": "Depositado",
		color: "#209869",
	},
	{
		"raw": "failed",
		"label": "Falha",
		color: "#D86470",
	},
];

export default function SingleUser(props) {
	const {userID} = useParams()

	const [loading, setLoading] = useState(true)
	const [revertSuspensionLoading, setRevertSuspensionLoading] = useState(false)
	const [tableData, setTableData] = useState({})
	const [orders, setOrders] = useState([])
	const [transactions, setTransactions] = useState([])
	const [withdrawals, setWithdrawals] = useState([])
	const [wallets, setWallets] = useState([])
	const [scheduledMUS, setScheduledMUS] = useState([])
  const [affiliate, setAffiliate] = useState([])
  const [comissions, setComissions] = useState([])
  const [toggleAffiliateLoading, setToggleAffiliateLoading] = useState(false)

  const payload = {
		page: 1,
		per_page: 5,
    customer_cpf: tableData?.cpf, 
    customer_email: tableData?.email,
	}

	function paginateOrders(page) {
		orderService.getOrders({ ...payload, page }).then(({ data }) => setOrders(data))
	}

	function paginateWithdrawals(page) {
		withdrawSevices.getWithdrawals({ ...payload, page }).then(setWithdrawals)
	}

	function paginateTransactions(page) {
		orderService.getTransactions({ ...payload, page }).then(setTransactions)
	}

	function paginateScheduledMUS(page) {
		concoursesService.getMusSchedule({ ...payload, page }).then(({ data }) => setScheduledMUS(data))
	}

  function paginateComissions(page) {
    affiliateService.getComissions({ ...payload, page }).then(({ data }) => setComissions(data))
  }

  const requests = {
    getOrders: (reqPayload) => orderService.getOrders({ ...payload, ...reqPayload }).then((response) => setOrders(response.data)),
    getWithdrawals: (reqPayload) => withdrawSevices.getWithdrawals({ ...payload, ...reqPayload }).then(setWithdrawals),
    getTransactions: (reqPayload) => orderService.getTransactions({ ...payload, ...reqPayload }).then(setTransactions),
    getWallets: (reqPayload) => userService.getWallets({ ...payload, ...reqPayload }).then(response => setWallets(response.result)),
    getMusSchedule: (reqPayload) => concoursesService.getMusSchedule({ ...payload, ...reqPayload }).then(response => setScheduledMUS(response.data)),
    getComissions: (reqPayload) => affiliateService.getComissions({ ...payload, ...reqPayload }).then(response => setComissions(response)),
    getAffiliateDetails: (reqPayload) => affiliateService.getDetails({ id: reqPayload?.defaultID || tableData?.id }).then(response => setAffiliate(response.data)),
  }

	const makeTableRequests = async (user) => {
		if (
			orders?.length >= 1
			|| transactions?.length >= 1
			|| withdrawals?.length >= 1
			|| wallets?.length >= 1
			|| scheduledMUS?.length >= 1
			|| comissions?.length >= 1
		) {
			setLoading(false)
			return
		}

		setLoading(true);
    
    const reqPayload = {
      customer_cpf: user?.cpf,
      customer_email: user?.email,
    }

		await Promise.all([
      requests.getOrders(reqPayload),
      requests.getWithdrawals(reqPayload),
      requests.getTransactions(reqPayload),
      requests.getWallets(reqPayload),
      requests.getMusSchedule(reqPayload),
      requests.getAffiliateDetails({defaultID: user?.id}),
      requests.getComissions(reqPayload),
		])

		setLoading(false);
	}

	async function handleRevertAccount() {
		setRevertSuspensionLoading(true)

		await users.revokeUserInactivation(userID)
			.then(makeApiRequests)
			.finally(() => setRevertSuspensionLoading(false))
	}

	const walletFields = {
		wallet: [
			{
				label: "Carteira digital",
				value: formatCurrency(wallets?.find(wallet => wallet.slug === 'default')?.balanceFloat)
			},
			{
				label: "Carteira MUS",
				value: `${wallets?.find(wallet => wallet.slug === 'mus')?.balance || 0} MUS`
			}
		],
	}

  const affiliateFields = {
    details: [
      {
        label: "Ref. do afiliado",
        value: affiliate?.ref
      },
      {
        label: "Status",
        value: affiliate?.is_active ? "Ativo" : "Inativo"
      },
      {
        label: "Taxa de comissão",
        value: `${affiliate?.commission_rate * 100}%`
      },
      {
        label: "Data de afiliação",
        value: formatDate(affiliate?.created_at)  
      },
    ],
  }

  const userFields = {
    client: [
      {
        label: "Nome",
        value: `${tableData?.first_name} ${tableData?.last_name}`
      },
      {
        label: "Email",
        value: tableData?.email
      },
      {
        label: "Telefone",
        value: formatStringByMask("(XX) XXXXX-XXXX", tableData?.phone)
      },
      {
        label: "CPF",
        value: formatStringByMask("XXX.XXX.XXX-XX", tableData?.cpf)
      },
    ],
    address: [
      {
        label: "Localidade",
        value: `${tableData?.address?.data.city} - ${tableData?.address?.data.state}`
      },
      {
        label: "Logradouro",
        value: tableData?.address?.data.address
      },
      {
        label: "CEP",
        value: formatStringByMask("XXXXX-XXX", tableData?.address?.data.zipcode)
      },
    ],
  }

  async function makeApiRequests(params) {
	  setLoading(true)

	  if ((tableData?.cpf || tableData?.email) && !params?.force) {
		  setLoading(false)

			return
	  }

    await users.getUsers({
        id: {
          raw: userID,
        }
      },
      {
        include: 'validations, roles, ocrs',
        page: 1,
      }
    )
      .then(async (response) => {
        setTableData(response.data[0])

        const imagesPreload = [tableData?.avatar];

        if (tableData?.avatar) {
          await imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.src = image;
            window[image] = newImage;
          });
        }

        await makeTableRequests(response.data[0])
      })
  }

  useEffect(() => {
    makeApiRequests()
  }, [userID])
  
  
  async function handleAffiliateStatus() {
    setToggleAffiliateLoading(true)
    await affiliateService.toggleAffiliate({ id: tableData?.id })
    await requests.getAffiliateDetails()
    setToggleAffiliateLoading(false)
  }
  
  async function handleAffiliateAdd() {
    setToggleAffiliateLoading(true)
    await affiliateService.transformUser({ id: tableData?.id })
    await makeApiRequests({ force: true })
    await requests.getAffiliateDetails()
    setToggleAffiliateLoading(false)
  }

  const isAffiliate = tableData?.roles?.data?.some(role => role.name === 'Afiliado')

	return (
    <main className="flex flex-col w-full h-full flex-1 mb-10">
      {loading && <Loading />}
      {!loading && (
        <>
          <Title label={`Usuário: ${userFields.client[0].value}`} />
	        {tableData?.deleted_at && (
						<div className="my-4">
		        <Alert severity="error">
			        <div className="flex w-full gap-x-4 flex-row justify-between items-center">
				        <div className={"flex-1"}>
					        <span>Este usuário encerrou a sua conta e não tem mais acesso a plataforma.</span>
					        <br />
					        <span>Data do encerramento: {formatDate(tableData.deleted_at)}</span>
				        </div>
				        <Button
					        small
					        color="green"
					        action={handleRevertAccount}
					        label="Reverter ação"
					        disabled={revertSuspensionLoading}
				        />
			        </div>
		        </Alert>
	        </div>
	        )}
          <div className="flex lg:flex-row flex-col gap-2 flex-1">
            <div className="gap-3 flex flex-col flex-1 min-w-[50%] bg-white rounded-lg">
              <div className="flex-row flex gap-2">
                <section className="flex flex-col gap-2 p-4 rounded-lg bg-neutral-7">
                  <div className="flex items-center gap-2 mb-4">
                    <img src={tableData?.avatar} alt="avatar" className="w-16 h-16 rounded-full"/>
                    <div className="flex flex-col">
                      <span className="text-2xs font-medium">{userFields.client[0].value}</span>
                      <span className="text-2xs">{userFields.client[1].value}</span>
                    </div>
                  </div>
                  <h3 className="pb-1 text-lg font-medium border-b border-solid border-neutral-6">Dados do cliente</h3>
                  {userFields.client.map((field) => {
                    if (!field.value) return null

                    return (<>
                      {(field.label !== 'Nome' && field.label !== 'Email') && (<div key={field.label} className="flex items-center gap-1">
                        <span className="text-sm font-medium">{field.label}:</span>
                        <span className="text-sm">{field.value || '-'}</span>
                      </div>)}
                    </>)
                  })}
                  {tableData?.address && (<>
                    <h3 className="pb-1 mt-6 text-lg font-medium border-b border-solid border-neutral-6">Endereço</h3>
                    {userFields.address.map((field) => (<>
                      <div key={field.label} className="flex items-center gap-1">
                        <span className="text-sm font-medium">{field.label}:</span>
                        <span className="text-sm">{field.value || '-'}</span>
                      </div>
                    </>))}
                  </>)}
                </section>
                <div className="flex flex-col min-w-[250px] gap-2">
                  <section className="flex flex-col gap-2 p-4 rounded-lg flex-1 bg-neutral-7">
                    <div>
                      <h3 className="pb-2 mb-4 text-lg font-medium border-b border-solid border-neutral-6">Carteiras</h3>
                      {walletFields.wallet.map((field) => {
                        if (!field.value) return null

                        return (<div key={field.label} className="flex flex-col mb-2">
                          <span className="text-sm font-medium mb-1">{field.label}</span>
                          <span className="text-sm">{field.value || '-'}</span>
                        </div>)
                      })}
                    </div>
                  </section>
                </div>
                <section className="flex flex-col gap-2 p-4 rounded-lg flex-1 bg-neutral-7">
                  <div>
                    <h3 className="pb-2 mb-4 text-lg font-medium border-b border-solid border-neutral-6">Afiliado</h3>
                    {isAffiliate ? affiliateFields.details.map((field) => {
                      if (!field.value) return null

                      return (
                        <div key={field.label} className="flex flex-col mb-2">
                          <span className="text-sm font-medium mb-1">{field.label}</span>
                          <span className="text-sm">{field.value || '-'}</span>
                        </div>
                      )
                    }) : (
                      <p>Este usuário ainda não é afiliado. Para adicionar clique no botão abaixo.</p>
                    )}
                  </div>
                  {isAffiliate ? (
                    <Button
                      label={affiliate?.is_active ? "Desabilitar afiliado" : "Habilitar afiliado"}
                      loading={toggleAffiliateLoading}
                      action={handleAffiliateStatus}
                      small
                      color="green"
                      className="w-fit min-w-[200px]"
                    />
                  ) : (
                    <Button
                      small
                      label="Adicionar novo afiliado"
                      loading={toggleAffiliateLoading}
                      action={handleAffiliateAdd}
                      color="green"
                      className="w-fit min-w-[200px]"
                    />
                  )}
                </section>
                <section className="flex flex-col flex-1 justify-between gap-2 p-4 rounded-lg bg-neutral-7">
                  <div>
                    <h3 className="pb-2 mb-4 text-lg font-medium border-b border-solid border-neutral-6">Validações do usuário</h3>
                    <div className="flex flex-col gap-y-3">
                      {validationList.map((validation) => {
                        const validationDate = tableData[validation.value]

                        return (<div key={validation.label} className="flex items-center gap-1">
                          {validation.icon}
                          <span className="text-sm font-medium">{validation.label}:</span>
                          <div className="flex items-center gap-1">
                          <span className="text-sm">
                            {validationDate ? moment(validationDate).format('DD/MM/YYYY HH:mm') : 'Não validado'}
                          </span>
                            {validationDate && <Verified className="text-primary text-[16px]"/>}
                          </div>
                        </div>)
                      })}
                    </div>
                  </div>

                  <div className="flex flex-col gap-y-3">
                    {/*<Button*/}
                    {/*  small*/}
                    {/*  action={() => history.push(`/usuarios/${userID}/validacoes`)}*/}
                    {/*  color="green"*/}
                    {/*  label="Ver histórico de atividades"*/}
                    {/*  className="w-fit min-w-[200px]"*/}
                    {/*/>*/}
                    {/*<span className="text-sm max-w-sm">*/}
                    {/*  Para conferir o histórico de atividades ou efetuar uma validação manual, clique no botão abaixo.*/}
                    {/*</span>*/}
                  </div>
                </section>
              </div>
              <section className="flex flex-col justify-between flex-1 gap-2 p-4 rounded-lg bg-neutral-7">
                <ResumeTable
                  loading={loading}
                  title={'Ordens de compra recentes'}
                  rows={orders.result}
                  pagination={orders.pagination}
                  paginate={paginateOrders}
                  renderRow={(row) => [
                    { headerName: 'Ordem', field: 'id' }, {
                      headerName: 'Cód. da transação', render: () => <div className="flex items-center gap-1">
                        <div style={{ width: 100 }} className="truncate" title={row.payment_tid || '-'}>
                          {row.payment_tid || '-'}
                        </div>
                        {row.payment_tid && <span className='flex-shrink-0 cursor-pointer'>
                <ContentCopy onClick={() => {
                  navigator.clipboard.writeText(row.payment_tid)
                  toast.success("Código copiado com sucesso!")
                }} style={{ fontSize: 18, fill: "#209869" }}/>
              </span>}
                      </div>
                    }, {
                      headerName: "Status", render: () => {
                        const status = orderStatus?.filter(data => row.status === data.raw)[0]
                        return <TagItem item={{ color: status?.color, label: status?.label }}/>
                      }
                    }, {
                      headerName: "Método pagamento", render: () => {
                        const paymentItem = orderPayment?.filter(data => row.payment_channel === data.raw)[0]
                        return (<div className="flex items-center gap-2">
                            {paymentItem?.icon}
                            <span>{paymentItem?.label || '-'}</span>
                          </div>)
                      }
                    }, { headerName: "Valor", render: () => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.amount) }, { headerName: "Data", render: () => moment(row.created_at, "DD-MM-YYYY HH:mm").format("DD/MM/YYYY HH:mm") }, {
                      headerName: "Ações", render: () => <OpenInNew onClick={() => handleSingleOrder(row.id)} style={{ cursor: "pointer", fill: "#209869" }}/>
                    },]}
                />
              </section>
              <section className="flex flex-col justify-between flex-1 gap-2 p-4 rounded-lg bg-neutral-7">
                <ResumeTable
                  loading={loading}
                  title={'Solicitações de saque recentes'}
                  rows={withdrawals.result}
                  pagination={withdrawals.pagination}
                  paginate={paginateWithdrawals}
                  renderRow={(row) => [
                    { headerName: "Solicitação", field: "id" }, { headerName: 'Valor', render: () => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.amount) }, { headerName: 'Data solicitação', render: () => moment(row.created_at, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY HH:mm') }, { headerName: 'Data atualização', render: () => moment(row.updated_at, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY HH:mm') }, {
                      headerName: "Status", render: () => {
                        const status = _status?.filter(data => row.status === data.raw)[0]
                        return <TagItem item={{ color: status?.color, label: status?.label }}/>
                      }
                    }, {
                      headerName: "Ações", render: () => (<Popover placement="top" showArrow={true}>
                        <PopoverTrigger>
                          <Tooltip title="Dados bancários" aria-label="bankData"><AccountBalanceIcon className="link"/></Tooltip>
                        </PopoverTrigger>
                        <PopoverContent>
                          <div className="p-2 flex flex-col">
                            <div>
                              <strong>CPF:</strong>
                              {' '}
                              <span>{row.bank_account?.document_number || ''}</span>
                            </div>
                            <div>
                              <strong>Banco:</strong>
                              {' '}
                              <span>{row.bank_account?.bank_institution || ''}</span>
                            </div>
                            <div>
                              <strong>Ag:</strong>
                              {' '}
                              <span>{row.bank_account?.account_agency || ''}</span>
                            </div>
                            <div>
                              <strong>CC:</strong>
                              {' '}
                              <span>{row.bank_account?.account_number || ''}</span>
                            </div>
                            <div>
                              <strong>Tipo:</strong>
                              {' '}
                              <span>{row.bank_account?.account_type ? _type.filter((item) => item.type === row.bank_account?.account_type)[0].label : ''}</span>
                            </div>
                          </div>
                        </PopoverContent>
                      </Popover>)
                    }]}
                />
              </section>
              <section className="flex flex-col justify-between flex-1 gap-2 p-4 rounded-lg bg-neutral-7">
                <ResumeTable
                  loading={loading}
                  title={'Transações recentes'}
                  rows={transactions.result}
                  pagination={transactions.pagination}
                  paginate={paginateTransactions}
                  renderRow={(row) => ([
                    {
                      headerName: 'Tipo', field: 'type', render: () => {
                        const paymentItem = transactionType?.filter(data => row.type === data.raw)[0]
                        return (<div className="flex items-center gap-2">
                            {paymentItem?.icon}
                            <span>{paymentItem?.label || '-'}</span>
                          </div>)
                      }
                    }, { headerName: 'Valor', render: () => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.amount) }, {
                      headerName: 'Carteira', render: () => {
                        const wallets = {
                          mus: {
                            icon: <img src={MUSIcon} width={24} height={24}/>, label: "MUS"
                          }, default: {
                            icon: <WalletOutlined width={24} height={24} className={'fill-primary'}/>, label: "Digital"
                          }
                        }

                        return <TagItem item={wallets[row?.wallet?.slug]}/>
                      }
                    }, { headerName: 'Descrição', columnClassName: 'max-w-[350px]', render: () => <span className={'line-clamp-2'}>{row.meta?.description || '-'}</span> }, { headerName: 'Data', render: () => moment(row.created_at, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY HH:mm') },])}
                />
              </section>
              <section className="flex flex-col justify-between flex-1 gap-2 p-4 rounded-lg bg-neutral-7">
                <ResumeTable
                  loading={loading}
                  title={'Liberações (MUS)'}
                  rows={scheduledMUS.result}
                  pagination={scheduledMUS.pagination}
                  paginate={paginateScheduledMUS}
                  renderRow={(data) => ([
                    { headerName: 'ID', render: () => data?.id }, { headerName: 'Qtd. MUS', render: () => data?.points }, {
                      headerName: 'Status', render: () => {
                        const statusItem = musStatus.filter((item) => item.raw === data.status)[0]
                        return (<TagItem item={{ color: statusItem?.color, label: statusItem?.label }}/>)
                      }
                    }, { headerName: "Data de liberação", render: () => moment(data?.deposit_date).format("DD/MM/YYYY HH:mm") }, { headerName: "Data de criação", render: () => moment(data?.earned_date).format("DD/MM/YYYY HH:mm") },])}
                />
              </section>
              <section className="flex flex-col justify-between flex-1 gap-2 p-4 rounded-lg bg-neutral-7">
                <ResumeTable
                  loading={loading}
                  title={'Comissões'}
                  rows={comissions.result}
                  pagination={comissions.pagination}
                  paginate={paginateComissions}
                  renderRow={(row) => ([
                    { headerName: 'ID', field: 'id' },
                    { headerName: 'Valor compra', render: () => formatCurrency(row?.order_total_amount) },
                    { headerName: 'Comissão', render: () => formatCurrency(row?.amount) },
                    { headerName: 'Status', render: () => <TagItem item={getStatusItem(row.status)}/> },
                    {
                      headerName: "Cód. da transação", render: () => (
                        <div className="flex items-center gap-1">
                          <div style={{ width: 100 }} className='truncate' title={row.uuid || '-'}>
                            {row.uuid || '-'}
                          </div>
                          {row.uuid && (<span className='flex-shrink-0 cursor-pointer'>
                            <ContentCopy onClick={() => {
                              navigator.clipboard.writeText(row.uuid)
                              toast.success("Código copiado com sucesso!")
                            }} style={{ fontSize: 18, fill: "#209869" }}/>
                          </span>)}
                        </div>
                      )
                    },
                    { headerName: 'Data compra', render: () => formatDate(row.created_at) },
                    { headerName: 'Data liberação', render: () => formatDate(row.locked_until) }
                  ])}
                />
              </section>
            </div>
          </div>
        </>)}
    </main>)
}
