import { history } from '../../../helpers/history'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import React, { useEffect, useState } from 'react'
import couponService from '../../../services/coupon'
import { useParams } from 'react-router-dom'
import { formatCurrency } from '../../../helpers/formatCurrency'
import formatDate from '../../../helpers/formatDate'
import { twMerge } from 'tailwind-merge'
import Loading from '../../../components/Loading'

export default function CouponSingle() {
  const {id} = useParams()
  const [data, setData] = useState([])

  useEffect(() => {
    couponService.getCoupon({id}).then(setData)    
  }, [])

  const items = [
    {
      title: "Método:",
      content: data?.type?.includes("cart") ? "Desconto no total do carrinho" : "Desconto por item"
    },
    {
      title: "Cupom:",
      content: data?.code
    },
    {
      title: "Descrição:",
      content: data?.description
    },
    {
      title: "Tipo:",
      content: data?.type?.includes("fixed") ? "Fixo" : "Porcentagem"
    },
    {
      title: "Valor:",
      content: data?.type?.startsWith("percentage") ? `${data?.value}%` : formatCurrency(data?.value)
    },
    {
      title: "Data inicial:",
      content: data?.start_date ? formatDate(data?.start_date) : "Não definido"
    },
    {
      title: "Data final:",
      content: data?.end_date ? formatDate(data?.end_date) : "Não definido"
    },
    {
      title: "Limite de uso (geral):",
      content: data?.max_uses
    },
    {
      title: "Limite de uso (por usuário):",
      content: data?.max_uses_per_user
    },
    {
      title: "Uso exclusivo:",
      content: data?.exclusive_use ? "Sim" : "Não"
    },
  ]

  if (!data?.code || !id) return <Loading />

  return (
	  <main id="withdraw">
		  <div id="title">
				<span className="back" onClick={() => history.back()}>
					<ArrowBackIosIcon/><span>Voltar</span>
				</span>
			  <h1>{['Cupom:', id].join(" ")}</h1>
		  </div>

		  <div className="flex flex-col mt-10 gap-4">
        <div className="bg-gray-100 p-4 rounded-lg">
          {items?.map((item, index) => (
            <div className={twMerge(
              'flex p-4 border-gray-300 items-center gap-x-2',
              index + 1 !== items?.length && "border-b"
            )}>
              <span className="font-medium text-lg">{item.title}</span>
              <span className="text-lg">{item.content}</span>
            </div>
          ))}
        </div>
      </div>
    </main>)
}
